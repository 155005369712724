.table-placeholder
    +rem(height, 20px)
    +rem(width, 100%)

.guide-title.loading-animation
    +rem(height, 24px)
    +rem(width, 30%)
.guide-description
    +rem(height, 16px)
    margin-bottom: .5rem

.paragraph.loading-animation
    +rem(height, 16px)
    margin-bottom: 0.5rem
    margin-top: 0.5rem