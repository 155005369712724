.pagination
    display: flex
    list-style-type: none
    margin: 0
    padding: 0
    .page-item
        .page-link
            align-items: center
            background-color: $white
            border-color: $border-color
            border-style: solid
            border-width: 2px
            border-right-width: 1px
            color: $gray //$text-color
            display: inline-flex
            // +rem(font-size, 21px)
            +rem(font-size, 14px)
            justify-content: center
            padding: .3rem .8rem
            transition: all .15s ease-in-out
            &:hover,
            &.active
                background-color: $grey //$gray-200
            &.active
                color: $black //$blue-200
            &.disabled
                opacity: 0.6
                pointer-events: none
        &+.page-item
            .page-link
                //font-size: 23px
                border-left-width: 1px
        &:first-child
            .page-link
                //font-size: 23px
                +rem(border-radius, 0 0 0 0) //+rem(border-radius, 7px 0 0 7px)
        &:last-child
            .page-link
                //font-size: 23px
                +rem(border-radius, 0 0 0 0) //+rem(border-radius, 0 7px 7px 0)
                border-right-width: 2px