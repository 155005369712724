/* CHUBB Theme */
$white: #FFFFFF
$black: #000000
$red: #E41913
$gray: #4B4E53
$grey: #F4F4F4
$chartreuse: #7ACB00

$text-color: #000000

$button-color: #7ACB00
$button-color-text: #000000

$button-color-disabled: #D7D7D7
$button-color-disabled-text: #AFAFAF
/* CHUBB Theme */



$font-path: '/assets/fonts/' !default
$image-path: '/assets/images/' !default

// Font weight
$weight-thin: 100
$weight-extra-light: 200
$weight-light: 300
$weight-regular: 400
$weight-medium: 500
$weight-semi-bold: 600
$weight-bold: 700
$weight-extra-bold: 800
$weight-black: 900

// Font family
$family-base: 'CordiaNew', sans-serif
// $family-base: 'DB Helvethaica X', Helvetica, sans-serif
$family-default: 'Helvetica Neue', Helvetica, Arial, sans-serif

// Color
$bg-color: #F7F8F9
//$text-color: #5C5C5C
$text-dark: #000000
$text-dark-pale: #424F5D
$blue-100: #00AEDD
$blue-200: #007AB5
$blue-300: #00487A
$border-color: #D9DADB
//$red: #FF2222
$gray-pale: #EAEAEA
$gray-100: #CCCCCC
$gray-200: #F3F5F9
$gray-placeholder: #CCCCCC
$green: #51B047
$primary: $blue-200
$gray-bg: #DEE3E5
$orange: #F16322

// icons
$icomoon-font-family: "CHUBB" !default
$icomoon-font-path: "/assets/fonts" !default

$npicon-child: "\e922"
$npicon-download: "\e921"
$npicon-ellipsis: "\e920"
$npicon-share: "\e91f"
$npicon-cog: "\e91e"
$npicon-trash: "\e91d"
$npicon-reserve-pending: "\e91c"
$npicon-admin-banner: "\e900"
$npicon-admin-department: "\e901"
$npicon-admin-hospital: "\e902"
$npicon-advantage-table: "\e903"
$npicon-back: "\e904"
$npicon-check: "\e905"
$npicon-cross: "\e906"
$npicon-down: "\e907"
$npicon-edit: "\e908"
$npicon-exception: "\e909"
$npicon-lock: "\e90a"
$npicon-logout: "\e90b"
$npicon-next: "\e90c"
$npicon-person: "\e90d"
$npicon-print: "\e90e"
$npicon-protection-cignaid: "\e90f"
$npicon-protection-nationid: "\e910"
$npicon-protection-passportid: "\e911"
$npicon-protection-username: "\e912"
$npicon-search: "\e913"
$npicon-special-condition: "\e914"
$npicon-user-booking: "\e915"
$npicon-user-guide: "\e916"
$npicon-user-payment: "\e917"
$npicon-user-protection: "\e918"
$npicon-user-report: "\e919"
$npicon-user-status: "\e91a"
$npicon-username: "\e91b"