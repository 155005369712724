/* You can add global styles to this file, and also import other style files */
@import 'assets/sass/abstracts/utilities'

body
    background-color: $bg-color
    color: $text-color
    font-family: $family-base
    // font-size: 20px
    font-size: 14px
    font-weight: $weight-regular
    // line-height: 1.2em
    line-height: 1.5em
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale

*
    text-rendering: optimizeLegibility
    -webkit-tap-highlight-color: transparent

a
    text-decoration: none !important

.cigna-logo
    background-image: url(#{$image-path}CHUBB.svg)
    background-position: center center
    background-repeat: no-repeat
    background-size: contain
    display: block
    height: 65px
    width: 165px
    &.lg
        height: 85px
        width: 155px

// .container
//     padding-left: 2rem
//     padding-right: 2rem

#page
    padding-top: 85px

#content
    padding-left: 185px
    padding-top: .5rem
    &.min-sidebar
        padding-left: 62px

#sidebar
    background-color: $white
    bottom: 0
    box-shadow: 1px 0px 0px 0px $border-color
    left: 0
    overflow-y: auto
    position: fixed
    top: 85px
    width: 185px
    z-index: 1000
    .sidebar-container
        min-height: 100%
        padding-bottom: 80px
        position: relative
    .nav
        .nav-item
            .nav-link
                align-items: center
                background-color: $white
                border: none
                color: $gray //$text-color
                cursor: pointer
                display: flex
                font-weight: $weight-medium
                height: 62px
                outline: none !important
                padding: 0.5rem 2rem 0.5rem 1rem
                position: relative
                transition: all .15s ease-in-out
                width: 100%
                +rem(font-size, 18px)
                .icon
                    color: $gray
                &:before
                    content: ''
                    background-color: transparent
                    display: block
                    height: 100%
                    left: 0
                    position: absolute
                    top: 0
                    width: 5px
                &:hover,
                &.active,
                &.is-active
                    background-color: $chartreuse //$green
                    color: $black //$white
                    .icon
                        color: $black //$white
                &.is-inactive
                    background-color: #F7F8F9
                    color: #d1cdcc
                    pointer-events: none
                &.active,
                &.is-active
                    &:before
                        background-color: $black //$blue-200
                .menu-title
                    flex: 1
                    line-height: 1em
                    margin-left: 0.5rem
                    text-align: left
                .sidebar-arrow
                    transform: rotate(0deg)
                    transition: transform .2s ease
                &.opened
                    .sidebar-arrow
                        transform: rotate(180deg)
                &.opened ~ .sub-nav
                    max-height: 500px
            .sub-nav
                list-style-type: none
                margin: 0
                max-height: 0
                overflow: hidden
                padding: 0
                transition: max-height .3s linear
                .nav-item
                    border-bottom: 1px solid $border-color
                    .nav-link
                        background-color: $bg-color
                        padding: 0.5rem 1rem 0.5rem 3.5rem
                        &:hover,
                        &.active
                            color: $chartreuse //$blue-200
                    &:last-child
                        border-bottom: none

    &.min-sidebar
        width: 62px
        .sidebar-container
            padding-bottom: 0
        .nav
            .nav-item
                .nav-link
                    width: 62px
                    .menu-title
                        overflow: hidden
                        white-space: nowrap
                        width: 0
                    .sidebar-arrow
                        display: none
                .sub-nav
                    display: none
        &:hover
            width: 185px
            .sidebar-container
                padding-bottom: 80px
            .nav
                .nav-item
                    .nav-link
                        width: auto
                        .menu-title
                            white-space: normal
                        .sidebar-arrow
                            display: block
                    .sub-nav
                        display: block
            .menu-call-center
                display: block
        .menu-call-center
            display: none
    .menu-call-center
        bottom: 0
        color: $black //$border-color
        // +rem(font-size, 20px)
        +rem(font-size, 18px) //+rem(font-size, 14px)
        font-weight: $weight-medium
        left: 0
        padding: 1rem
        position: absolute
        right: 0
        a
            color: $black //$border-color
            transition: all .15s ease-in-out
            &:hover
                color: $chartreuse //$text-color

// Login footer
.call-center
    // +rem(font-size, 22px)
    +rem(font-size, 16px)
    font-weight: $weight-light
    span
        // +rem(font-size, 24px)
        // +rem(font-size, 18px)
        // color: $blue-200
        // font-weight: $weight-bold
        strong
            color: $blue-200
            // +rem(font-size, 30px)
            // +rem(font-size, 22px)
            font-weight: $weight-regular

@media print
    body
        background-color: $white
        color: $black
    #page
        padding-top: 0
    #header
        display: none
    #sidebar
        display: none
    #content
        padding: 0
    .container
        width: 21cm

.cke_wysiwyg_div
    padding: 8px !important

// Modal
.action-modal
    .swal2-modal
        padding: 4rem 2rem
.spacial-modal
    .swal2-modal
        padding: 4rem 2rem
        height: 380px   
.swal2-header
    display: flex !important
.swal2-popup
    display: flex !important
    border-radius: 0 !important
.swal2-title
    justify-content: center
    width: 100%
.swal2-close
    color: $chartreuse !important
    