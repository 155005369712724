input
    &[type=text],
    &[type=password],
    &[type=search]
        appearance: none
        color: $black //$blue-300
        +rem(font-size, 22px)
        font-weight: $weight-medium
        outline: none !important
        width: 100%
    &.form-control
        // +rem(font-size, 24px)
        +rem(font-size, 16px)
    &[type=search]
        &::-webkit-search-cancel-button
            display: none

.form-group
    .icon
        color: $blue-300
        +rem(font-size, 22px)
    .has-error
        .icon
            color: $red
        label
            color: $red
    label
        // +rem(font-size, 22px)
        +rem(font-size, 16px)
        font-weight: $weight-medium
    &.has-error
        input
            border-color: $red
            +placeholder
                color: $red
        label,
        .help-block
            color: $red !important
.select,
.form-control
    border-radius: 0
    min-height: 59px
    // +rem(font-size, 24px)
    +rem(font-size, 16px)
    font-weight: $weight-medium
    padding: 1rem
    &:focus
        border-color: $chartreuse //$green
        box-shadow: none
    +placeholder
        color: $gray-placeholder
        font-weight: $weight-regular
    &.form-sm
        // +rem(font-size, 19px)
        +rem(font-size, 14px)
        font-weight: $weight-regular
        //height: 36px
        min-height: 36px
        padding: 0.3rem 1rem
.select
    display: flex !important /* CHUBB Theme */
    flex: 1 1 auto !important /* CHUBB Theme */
    padding: 0 !important /* CHUBB Theme */
    position: relative !important /* CHUBB Theme */
    border: 1px !important /* CHUBB Theme */
    border-style: none none solid none !important /* CHUBB Theme */
    border-color: $chartreuse !important /* CHUBB Theme */
    background-color: $grey !important /* CHUBB Theme */
    font-size: 22px !important /* CHUBB Theme */
    //background-color: $grey //$white
    //border: 1px solid #ced4da
    //flex: 1 1 auto
    padding: 0
    position: relative
    &:before
        content: ''
        border-top: 5px solid $black //5px solid $gray-placeholder
        border-left: 5px solid transparent
        border-right: 5px solid transparent
        margin-top: -4px
        pointer-events: none
        position: absolute
        right: .8rem
        top: 50%
    &:after
        content: ''
        border-top: 4px solid $grey //4px solid $white
        border-left: 4px solid transparent
        border-right: 4px solid transparent
        margin-top: -4px
        pointer-events: none
        position: absolute
        right: calc(.8rem + 1px)
        top: 50%
    &.not-empty
        &.select
            &:before
                border-top: 5px solid $chartreuse !important /* CHUBB Theme */
        select
            color: $black !important /* CHUBB Theme */ //$blue-300
            font-weight: $black !important /* CHUBB Theme */ //$weight-medium
            border-bottom: 2px solid $chartreuse !important /* CHUBB Theme */
            &~label
                color: $chartreuse !important /* CHUBB Theme */
                font-size: 18px !important /* CHUBB Theme */
    &+.select
        border-left: none
    &.day
        flex: 1 1 100%
        max-width: 100%
    &.month
        flex: 1 1 100%
        max-width: 100%
    &.year
        flex: 1 1 100%
        max-width: 100%
    &.select-sm
        //height: 36px
        min-height: 36px
        select
            +rem(font-size, 14px)
            //height: 100%
            padding: 0.3rem 1rem
            min-height: 36px
    select
        line-height: 1.5
        appearance: none
        background-color: transparent
        border: none
        color: $black //$gray-placeholder
        cursor: pointer
        font-weight: $weight-regular
        min-height: 59px
        padding: 1rem
        width: 100%        
        font-size: 22px !important /* CHUBB Theme */
        padding: 20px 16px 4px 16px !important /* CHUBB Theme */
        &~label
            font-size: 0px !important /* CHUBB Theme */
            color: $chartreuse !important /* CHUBB Theme */
            margin-left: 1.0rem !important /* CHUBB Theme */
            position: absolute !important /* CHUBB Theme */
        option:not(:first-of-type)
            color: $black //$blue-300        
        option:hover  /* CHUBB Theme */
            background-color: $grey  /* CHUBB Theme */
            color: $black !important  /* CHUBB Theme */
        &:focus
            outline: none
            background-color: $white  /* CHUBB Theme */
            position: absolute
            z-index: 100
        &::-ms-expand
            display: none
        &.disabled
            pointer-events: none
        &.has-error
            color: $red !important

.input-group
    display: flex
    .form-control
        flex: 1 1 auto
        position: relative
        width: 1%
    .cn-btn
        border-radius: 0
        height: 55px
        min-width: 150px

.input-search
    position: relative
    .form-control
        padding-right: 2.5rem
    button
        align-items: center
        background-color: transparent
        border: none
        color: $blue-300
        display: flex
        height: 100%
        justify-content: center
        outline: none
        position: absolute
        right: 0
        top: 0
        width: 36px

.form-switch
    cursor: pointer
    display: inline-block
    margin: 0
    -webkit-tap-highlight-color: transparent
    i
        background-color: $grey //$blue-100
        +rem(border-radius, 26px)
        display: inline-block
        +rem(height, 26px)
        margin-right: 0.5rem
        position: relative
        vertical-align: text-bottom
        transition: all .3s linear
        +rem(width, 46px)
        &:before
            content: ''
            background-color: $grey //$white
            +rem(border-radius, 11px)
            +rem(height, 22px)
            left: 0
            position: absolute
            transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1)
            transition: all 0.25s linear
            +rem(width, 42px)
        &:after
            content: ''
            background-color: $white //$blue-100
            border: 2px solid $grey //$white
            +rem(border-radius, 11px)
            +rem(height, 22px)
            left: 0
            position: absolute
            transform: translate3d(2px, 2px, 0)
            transition: all 0.2s ease-in-out
            +rem(width, 22px)
    &:active
        i
            &:after
                transform: translate3d(2px, 2px, 0)
                +rem(width, 28px)
        input:checked + i:after
            transform: translate3d(16px, 2px, 0)
    .form-status
        &:before
            content: attr(data-private)
    input
        display: none
        &:checked + i
            background-color: $chartreuse //$blue-100
        &:checked + i:before
            transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0)
        &:checked + i:after
            background-color: $gray-200
            border: 2px solid $chartreuse //$blue-100
            transform: translate3d(22px, 2px, 0)
        &:checked + i + .form-status
            &:before
                content: attr(data-publish)
    &.disabled
        opacity: 0.5
        pointer-events: none

label.checkbox
    cursor: pointer
    padding-left: 2rem
    position: relative
    white-space: nowrap
    i
        display: block
        +rem(height, 20px)
        left: 0
        position: absolute
        top: 0
        +rem(width, 20px)
        &:before,
        &:after
            content: ''
            background-color: transparent
            border: 2px solid $chartreuse //2px solid $blue-100
            +rem(border-radius, 2px)
            display: block
            left: 0
            +rem(height, 20px)
            position: absolute
            top: 0
            +rem(width, 20px)
        &:after
            color: $chartreuse
            background-image: url(#{$image-path}icons/check.svg)
            background-position: center center
            background-repeat: no-repeat
            background-size: 0
            border: 2px solid transparent
            transition: all 0.08s ease-in-out
    input
        display: none
        &:checked + i:after
            background-size: 100%

.file-input
    label
        cursor: pointer
    input
        display: none

.image-control
    position: relative
    .toolbars
        align-items: center
        background-color: rgba($black, 0.5)
        display: flex
        list-style-type: none
        opacity: 0
        padding: 0.5rem
        position: absolute
        right: 0
        transition: all .3s ease-in-out
        visibility: hidden
        top: 0
        z-index: 100
        button
            align-items: center
            background-color: transparent
            border: none
            color: $white
            display: flex
            +rem(font-size, 18px)
            +rem(height, 32px)
            justify-content: center
            outline: none !important
            +rem(width, 32px)
    &:hover
        .toolbars
            opacity: 1
            visibility: visible

form
    .loading-content
        display: none
    &.loading
        position: relative
        *
            &:not(.cn-btn)
                opacity: 0.75
                pointer-events: none
        .loading-content
            align-items: center
            background-color: transparent
            display: flex
            height: 100%
            justify-content: center
            left: 0
            position: absolute
            top: 0
            width: 100%
            .lds-spinner
                color: official
                display: inline-block
                height: 64px
                position: relative
                width: 64px
                div
                    animation: lds-spinner 1.2s linear infinite
                    transform-origin: 32px 32px
                    &:after
                        content: ''
                        background: $text-dark
                        border-radius: 20%
                        display: block
                        height: 14px
                        left: 29px
                        position: absolute
                        top: 3px
                        width: 5px
                    &:nth-child(1)
                        transform: rotate(0deg)
                        animation-delay: -1.1s
                    &:nth-child(2)
                        transform: rotate(30deg)
                        animation-delay: -1s
                    &:nth-child(3)
                        transform: rotate(60deg)
                        animation-delay: -0.9s
                    &:nth-child(4)
                        transform: rotate(90deg)
                        animation-delay: -0.8s
                    &:nth-child(5)
                        transform: rotate(120deg)
                        animation-delay: -0.7s
                    &:nth-child(6)
                        transform: rotate(150deg)
                        animation-delay: -0.6s
                    &:nth-child(7)
                        transform: rotate(180deg)
                        animation-delay: -0.5s
                    &:nth-child(8)
                        transform: rotate(210deg)
                        animation-delay: -0.4s
                    &:nth-child(9)
                        transform: rotate(240deg)
                        animation-delay: -0.3s
                    &:nth-child(10)
                        transform: rotate(270deg)
                        animation-delay: -0.2s
                    &:nth-child(11)
                        transform: rotate(300deg)
                        animation-delay: -0.1s
                    &:nth-child(12)
                        transform: rotate(330deg)
                        animation-delay: 0s

.form
    text-align: left
    label
        color: $text-dark
        +rem(font-size, 17px)
        font-weight: $weight-regular
        margin: 0