@import url('https://fonts.googleapis.com/css?family=Prompt:400,500&display=swap&subset=thai')

@font-face
    font-family: 'DB Helvethaica X'
    src: url('#{$font-path}DBHelvethaicaX-45Li.woff2') format('woff2'), url('#{$font-path}DBHelvethaicaX-45Li.woff') format('woff')
    font-weight: $weight-light
    font-style: normal

@font-face
    font-family: 'DB Helvethaica X'
    src: url('#{$font-path}DBHelvethaicaX-55Regular.woff2') format('woff2'), url('#{$font-path}DBHelvethaicaX-55Regular.woff') format('woff')
    font-weight: $weight-regular
    font-style: normal

@font-face 
    font-family: 'DB Helvethaica X'
    src: url('#{$font-path}DBHelvethaicaX-65Med.woff2') format('woff2'), url('#{$font-path}DBHelvethaicaX-65Med.woff') format('woff')
    font-weight: $weight-medium
    font-style: normal

@font-face
    font-family: 'DB Helvethaica X'
    src: url('#{$font-path}DBHelvethaicaX-75Bd.woff2') format('woff2'), url('#{$font-path}DBHelvethaicaX-75Bd.woff') format('woff')
    font-weight: $weight-bold
    font-style: normal

@font-face
    font-family: '#{$icomoon-font-family}'
    src:  url('#{$font-path}/#{$icomoon-font-family}.eot?42xbab')
    src:  url('#{$font-path}/#{$icomoon-font-family}.eot?42xbab#iefix') format('embedded-opentype'), url('#{$font-path}/#{$icomoon-font-family}.ttf?42xbab') format('truetype'), url('#{$font-path}/#{$icomoon-font-family}.woff?42xbab') format('woff'), url('#{$font-path}/#{$icomoon-font-family}.svg?42xbab##{$icomoon-font-family}') format('svg')
    font-weight: normal
    font-style: normal

