button
    &:focus
        outline: none   
.cn-btn
    min-height: 59px
    align-items: center
    border: 0px solid transparent
    //+rem(border-radius, 7px)
    color: $white
    display: inline-flex
    // +rem(font-size, 24px)
    +rem(font-size, 30px) //+rem(font-size, 16px)
    font-weight: $weight-medium
    justify-content: center
    outline: none !important
    padding: 1rem 1rem
    text-decoration: none !important
    transition: all .3s ease-in-out
    &.blue
        background-color: $blue-100
        border: 0px solid $blue-100
        // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */ &:hover
        // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */ background-color: lighten($blue-100, 10%)
        &:active
            background-color: darken($blue-100, 10%)
        &.btn-outline
            color: $blue-100
            svg
                *
                    fill: $blue-100
                    transition: all .3s ease-in-out
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */ &:hover
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */     color: $white
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */     svg
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */         *
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */             fill: $white
    &.blue-theme
        background-color: $chartreuse //$blue-200
        border: 1px solid $chartreuse //1px solid $blue-200
        color: $black //$white
        // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */ &:hover
        // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */     background-color: darken($chartreuse, 10%) //lighten($blue-200, 5%)
        &:active
            background-color: darken($chartreuse, 10%) //darken($blue-200, 10%)
    &.gray
        background-color: #D7D7D7 //$green
        border: 0px solid #D7D7D7 //$green
        color: $grey //$white
        // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */ &:hover
        // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */     background-color: darken(#D7D7D7, 5%) //lighten($green, 10%)
        &:active
            background-color: darken(#D7D7D7, 10%) //darken($green, 10%)
        &.btn-outline
            color: #D7D7D7k //$green
            background-color: transparent 
            border: 1px solid #D7D7D7
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */ &:hover
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */     background-color: transparent //$gray-pale
            &:active
                background-color: transparent //darker($gray-pale, 10%)
        &.activated
            background-color: #007A3E
            border-color: #007A3E
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */ &:hover
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */     background-color: lighten(#007A3E, 5%)
            &:active
                background-color: darken(#007A3E, 10%)
    &.black
        background-color: $black //$green
        border: 0px solid $black //$green
        color: $white //$white
        // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */ &:hover
        // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */     background-color: darken($black, 5%) //lighten($green, 10%)
        &:active
            background-color: darken($black, 10%) //darken($green, 10%)
        &.btn-outline
            color: $black //$green
            background-color: transparent 
            border: 1px solid $black
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */ &:hover
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */     background-color: transparent //$gray-pale
            &:active
                background-color: transparent //darker($gray-pale, 10%)
        &.activated
            background-color: #007A3E
            border-color: #007A3E
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */ &:hover
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */     background-color: lighten(#007A3E, 5%)
            &:active
                background-color: darken(#007A3E, 10%)
    &.green
        background-color: $chartreuse //$green
        border: 0px solid $chartreuse //$green
        color: $black //$white
        // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */ &:hover
        // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */     background-color: darken($chartreuse, 5%) //lighten($green, 10%)
        &:active
            background-color: darken($chartreuse, 10%) //darken($green, 10%)
        &.btn-outline
            color: $chartreuse //$green
            background-color: transparent 
            border: 1px solid $chartreuse
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */ &:hover
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */     background-color: transparent //$gray-pale
            &:active
                background-color: transparent //darker($gray-pale, 10%)
        &.activated
            background-color: #007A3E
            border-color: #007A3E
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */ &:hover
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */     background-color: lighten(#007A3E, 5%)
            &:active
                background-color: darken(#007A3E, 10%)
        &.disabled,
        &:disabled
            background-color: $button-color-disabled
            border: 0px solid $button-color-disabled
            color: $button-color-disabled-text

    &.red
        background-color: $red
        border: 0px solid $red
        color: $white
        // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */ &:hover
        // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */     background-color: lighten($red, 10%)
        &:active
            background-color: darken($red, 10%)
    &.white
        background-color: $white
        border: 1px solid $border-color
        color: $text-color
        // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */ &:hover
        // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */     background-color: darken($white, 5%)
        &:active
            background-color: darken($white, 10%)
    &.link
        font-size: 25px
        background-color: transparent
        color: $black //$text-dark
        padding-left: 0
        padding-right: 0
        span
            position: relative
            &:after
                content: ''
                background-color: $text-dark
                bottom: 0
                height: 1px
                left: 0
                position: absolute
                right: 0
                width: 100%
        &:hover
            color: $black //$text-dark-pale
        &.link-default
            color: $black //$blue-200
            span
                &:after
                    background-color: $black //$blue-200
            &:hover
                color: $black //$blue-100
                span
                    &:after
                        background-color: $black //$blue-100
    &.reserve
        background-color: transparent
        border: 1px solid $chartreuse //1px solid $text-color
        color: $chartreuse //$text-color
        transition: all .15s ease-in-out
        &.active
            background-color: $chartreuse //$blue-200
            border: 1px solid $chartreuse //1px solid $blue-200
            color: $black //$white
            &:hover
                background-color: darken($chartreuse, 5%) //lighten($blue-200, 5%)
            &:active
                background-color: darken($chartreuse, 5%) //darken($blue-200, 5%)
        &:hover
            background-color: darken($white, 5%)
        &:active
            background-color: darken($white, 10%)
    &.full
        width: 100%
    &.half
        flex: 1 1 auto
    &.btn-sm
        // +rem(font-size, 18px)
        +rem(font-size, 14px)
        font-weight: $weight-regular
        +rem(min-height, 28px)
        padding: 0.5rem 1rem //.2rem 1rem
    &.btn-md
        // +rem(font-size, 20px)
        +rem(font-size, 16px)
        font-weight: $weight-regular
        padding: 0.5rem 1rem
    &.btn-icon
        +rem(font-size, 26px)
        padding: 0.5rem
        &.btn-sm
            +rem(font-size, 18px)
            padding: 0.5rem
    &.btn-outline
        background-color: transparent
        border-width: 2px
    &.saved
        background-color: #D7D7D7 //transparent
        border: 1px solid #D7D7D7 //1px solid $text-color
        color: #AFAFAF //$text-color
        +rem(font-size, 11px)
        margin: 0
        opacity: 0.5
        padding-left: 0.5rem
        padding-right: 0.5rem
    &.delete
        background-color: $chartreuse //$blue-200
        +rem(font-size, 16px)
        +rem(height, 28px)
        +rem(width, 28px)
        &:hover
            background-color: darken($chartreuse, 10%) //lighten($blue-200, 10%)
        &:active
            background-color: darken($chartreuse, 10%) //darken($blue-200, 10%)
        i.npicon-trash
            color: $black
    &.default-width
        min-width: 260px
    &.pointer
        cursor: pointer
    &.disabled,
    &[disabled]
        opacity: 0.5
        pointer-events: none
    &.cursor-move
        cursor: move

.field
    color: $blue-300
    &.empty
        color: $gray-placeholder

label
    &.cn-btn
        background-color: transparent
        border: 1px solid $text-color
        color: $text-color
        cursor: pointer
        transition: all .15s ease-in-out
        &:hover
            background-color: darken($white, 5%)
        &:active
            background-color: darken($white, 10%)
        input[type=radio]
            display: none

.radio-button
    label
        font-size: 22px
        &.cn-btn
            background-color: transparent //$white
            border: 1px solid $chartreuse //1px solid $text-color
            color: $chartreuse !important //$text-color !important
            cursor: pointer
            transition: all .15s ease-in-out
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */ &:hover
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */     background-color: darken($white, 5%)
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */ &:active
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */     background-color: darken($white, 10%)
    input[type=radio]
        display: none
        &:checked + label.cn-btn
            background-color: $chartreuse //$blue-200
            border: 1px solid $chartreuse //1px solid $blue-200
            color: $black !important //$white !important
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */ &:hover
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */     background-color: darken($chartreuse, 5%) //lighten($blue-200, 5%)
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */ &:active
            // /* CHUBB Theme ไม่เปลี่ยนสีเมื่อ hover */     background-color: darken($chartreuse, 5%) //darken($blue-200, 5%)

.checkbox-button
    label
        align-items: center
        cursor: pointer
        display: flex
        i
            background-image: none
            background-color: $white
            border: 2px solid $chartreuse //2px solid $border-color
            border-radius: 0px //3px
            display: inline-block
            height: 18px
            position: relative
            width: 18px
            &:before
                content: ''
                align-items: center
                display: flex
                bottom: 0
                font-family: $icomoon-font-family
                +rem(font-size, 13px)
                justify-content: center
                left: 0
                margin-left: -3px
                margin-top: 1px
                position: absolute
                right: 0
                top: 0
    input[type=checkbox]
        display: none
        &:checked + label
            i
                border: 2px solid $chartreuse //2px solid $blue-200
                &:before
                    content: $npicon-check
                    color: $chartreuse //$blue-200
    &:hover
        label
            i
                border: 2px solid darken($chartreuse, 10%) //2px solid darken($border-color, 10%)
    &.white
        label
            i
                border: 2px solid $white
        input[type=checkbox]
            &:checked + label
                i
                    background-color: $chartreuse //$blue-200
                    border: 2px solid $white
                    &:before
                        color: $white

.cross-btn
    color: $black //$blue-200
    cursor: pointer
    &:hover
        color: lighten($black, 10%) //lighten($blue-200, 10%)
    &:active
        color: darken($black, 10%) //darken($blue-200, 10%)