.icon
    align-items: center
    background-position: center center
    background-repeat: no-repeat
    background-size: 60%
    display: flex
    flex: 0 0 34px
    justify-content: center
    +rem(height, 34px)
    +rem(width, 34px)
    margin: 0

#sidebar
    .nav-link
        .icon
            color: $gray-100
            +rem(font-size, 19px)
            transition: all .15s ease-in-out
            svg
                fill: $gray-100
                transition: all .15s ease-in-out
                *
                    fill: $gray-100
                    transition: all .15s ease-in-out
        &.active,
        &:hover
            .icon
                color: $white
                svg
                    fill: $white
                    *
                        fill: $white

#protection-tabs
    .nav-link
        .icon
            svg
                fill: $blue-200
                transition: all .15s ease-in-out
                *
                    fill: $blue-200
                    transition: all .15s ease-in-out

.insurance-panel
    .nav-link
        .icon
            svg
                fill: $white
                *
                    fill: $white

.guide-item
    a
        .icon
            svg
                fill: $text-color
                *
                    fill: $text-color
        &:hover
            .icon
                svg
                    fill: $text-dark
                    *
                        fill: $text-dark