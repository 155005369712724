@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?42xbab');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?42xbab#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?42xbab') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?42xbab') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?42xbab##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="npicon-"], [class*=" npicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.npicon-child {
  &:before {
    content: $npicon-child; 
  }
}

.npicon-download {
  &:before {
    content: $npicon-download; 
  }
}

.npicon-ellipsis {
  &:before {
    content: $npicon-ellipsis; 
  }
}
.npicon-share {
  &:before {
    content: $npicon-share; 
  }
}
.npicon-cog {
  &:before {
    content: $npicon-cog; 
  }
}
.npicon-trash {
  &:before {
    content: $npicon-trash; 
  }
}
.npicon-reserve-pending {
  &:before {
    content: $npicon-reserve-pending; 
  }
}
.npicon-admin-banner {
  &:before {
    content: $npicon-admin-banner; 
  }
}
.npicon-admin-department {
  &:before {
    content: $npicon-admin-department; 
  }
}
.npicon-admin-hospital {
  &:before {
    content: $npicon-admin-hospital; 
  }
}
.npicon-advantage-table {
  &:before {
    content: $npicon-advantage-table; 
  }
}
.npicon-back {
  &:before {
    content: $npicon-back; 
  }
}
.npicon-check {
  &:before {
    content: $npicon-check; 
  }
}
.npicon-cross {
  &:before {
    content: $npicon-cross; 
  }
}
.npicon-down {
  &:before {
    content: $npicon-down; 
  }
}
.npicon-edit {
  &:before {
    content: $npicon-edit; 
  }
}
.npicon-exception {
  &:before {
    content: $npicon-exception; 
  }
}
.npicon-lock {
  &:before {
    content: $npicon-lock; 
  }
}
.npicon-logout {
  &:before {
    content: $npicon-logout; 
  }
}
.npicon-next {
  &:before {
    content: $npicon-next; 
  }
}
.npicon-person {
  &:before {
    content: $npicon-person; 
  }
}
.npicon-print {
  &:before {
    content: $npicon-print; 
  }
}
.npicon-protection-cignaid {
  &:before {
    content: $npicon-protection-cignaid; 
  }
}
.npicon-protection-nationid {
  &:before {
    content: $npicon-protection-nationid; 
  }
}
.npicon-protection-passportid {
  &:before {
    content: $npicon-protection-passportid; 
  }
}
.npicon-protection-username {
  &:before {
    content: $npicon-protection-username; 
  }
}
.npicon-search {
  &:before {
    content: $npicon-search; 
  }
}
.npicon-special-condition {
  &:before {
    content: $npicon-special-condition; 
  }
}
.npicon-user-booking {
  &:before {
    content: $npicon-user-booking; 
  }
}
.npicon-user-guide {
  &:before {
    content: $npicon-user-guide; 
  }
}
.npicon-user-payment {
  &:before {
    content: $npicon-user-payment; 
  }
}
.npicon-user-protection {
  &:before {
    content: $npicon-user-protection; 
  }
}
.npicon-user-report {
  &:before {
    content: $npicon-user-report; 
  }
}
.npicon-user-status {
  &:before {
    content: $npicon-user-status; 
  }
}
.npicon-username {
  &:before {
    content: $npicon-username; 
  }
}

