h1
    &.page-title
        color: $black //$blue-200
        // +rem(font-size, 40px)
        +rem(font-size, 32px) //+rem(font-size, 28px)
        font-weight: $weight-medium
        margin-bottom: 1rem
        a
            color: $black //$blue-200
            i
                +rem(font-size, 22px)
                margin-right: 0.5rem

h2
    &.page-subtitle
        color: $black //$blue-200
        // +rem(font-size, 32px)
        +rem(font-size, 22px)
        font-weight: $weight-medium
    &.guide-title
        color: $text-dark
        // +rem(font-size, 30px)
        +rem(font-size, 20px)
        font-weight: $weight-medium
        margin: 0
    &.action-title
        +rem(font-size, 26px)
        font-weight: $weight-medium
        margin-bottom: 2rem
        line-height: 1.5em

h3
    &.panel-title
        color: $black //$blue-200
        // +rem(font-size, 30px)
        +rem(font-size, 22px)
        font-weight: $weight-regular
        margin-bottom: .5rem !important
        padding: 0.5rem 0
    &.result-title
        color: $black //$text-dark
        +rem(font-size, 18px)
        font-weight: $weight-bold
        margin: 1.5rem 0 1rem
    &.block-title
        color: $black //$blue-200
        +rem(font-size, 16px)
        font-weight: $weight-medium

strong,
b
    font-weight: $weight-medium

.text-gray-pale
    color: #000000 !important //$gray-placeholder !important

.text-primary-dark
    color: #000000 !important //$blue-300 !important

.text-approved
    color: #7ACB00 !important //#193f0d !important
.text-rejected
    color: #000000 !important //#800000 !important