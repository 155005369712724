.modal-container
    background-color: rgba($white, 0.6)
    bottom: 0
    left: 0
    position: fixed
    right: 0
    top: 0
    z-index: 1000
    .modal-content
        background-color: $white
        box-shadow: 0px 0px 10px 0px rgba($black, .1)
        +rem(border-radius, 7px)
        padding: 1rem
        .modal-header
            margin-bottom: 1rem
            h1
                color: $text-color
                +rem(font-size, 26px)
                font-weight: $weight-medium
        .modal-footer
            display: flex