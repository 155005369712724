table
    &.default-table
        margin-bottom: 1rem
        width: 100%
        thead
            tr
                th
                    background-color: $chartreuse //$blue-200
                    color: $black //$white
                    font-weight: $weight-regular
                    text-align: center
                    &.sortable
                        cursor: pointer
                        padding-right: 0.8rem
                        position: relative
                        &:before,
                        &:after
                            content: ''
                            display: inline-block
                            width: 0
                            height: 0
                            border-style: solid
                            border-width: 0 5px 5px 5px
                            border-color: transparent transparent $black transparent //transparent transparent $white transparent
                            margin-left: 0.5rem
                            margin-top: -5px
                            opacity: 0.4
                            position: absolute
                            right: 0.5rem
                            top: 50%
                            transition: all .15s ease-in-out
                        &:before
                            transform: translateY(-75%) rotate(0)
                        &:after
                            transform: translateY(75%) rotate(180deg)
                        &:hover
                            &:before,
                            &:after
                                opacity: 0.6
                        &.desc
                            &:before
                                opacity: 0
                            &:after
                                opacity: 1
                                // content: ''
                                // display: inline-block
                                // width: 0
                                // height: 0
                                // border-style: solid
                                // border-width: 5px 5px 0 5px
                                // border-color: $white transparent transparent transparent
                                // margin-left: 0.5rem
                                // opacity: 1
                                // transform: translateY(-2px) rotate(180deg)
                        &.asc
                            &:before
                                opacity: 1
                            &:after
                                opacity: 0
        tr
            td,
            th
                // +rem(font-size, 20px)
                +rem(font-size, 16px)
                padding: .9rem .5rem
                transition: all .15s ease-in-out
            &.table-separate
                td
                    background-color: $bg-color
                    padding: .5rem !important
        tbody
            tr
                border-bottom: 1px dotted $border-color
                td
                    color: $black
                    background-color: $white
                    font-weight: $weight-regular
                    text-align: center
                    vertical-align: top
                    &.active
                        color: $black //$blue-100
                    &.inactive
                        color: $black //$red
            .insurance-logo
                flex: 1 1 120px
                max-width: 120px
                text-align: center
            .button-action
                flex: 1 1 195px
                max-width: 195px
                text-align: left
            &.recent
                border-bottom: 5px solid $gray-pale
                tr
                    border-bottom: 1px dotted $border-color
            &.multiple-line
                border-bottom: 1px dotted $border-color
                tr
                    border-bottom: none
        &.small-text
            tr
                td, th
                    +rem(font-size, 14px)
                    padding: .2rem .5rem
                &:first-child
                    td, th
                        padding-top: 0.9rem
                &:last-child
                    td, th
                        padding-bottom: 0.9rem
    &.info-table
        margin-bottom: 1rem
        width: 100%
        tr
            border-bottom: 1px dotted $gray-pale
            th
                background-color: $chartreuse //$gray-bg
                color: $text-color
                // +rem(font-size, 20px)
                +rem(font-size, 15px)
                font-weight: $weight-regular
                padding: 0.6rem 0.4rem
            td
                color: $text-dark
                // +rem(font-size, 20px)
                +rem(font-size, 15px)
                font-weight: $weight-medium
                padding: 0.4rem
                vertical-align: top
    &.summary-table
        margin-bottom: 1rem
        width: 100%
        th
            font-weight: $weight-regular
        td
            vertical-align: top
    .table-action
        align-items: center
        color: $black //$text-color
        display: inline-flex
        i
            color: $chartreuse //$blue-200
            margin-right: 0.4rem
        span
            color: $black 
            margin-top: 3px
        &:hover
            color: $chartreuse //$blue-200
    &.exclusion-table
        tbody
            tr
                td
                    color: $white
                    transition: all .15s ease-in-out
                    @media print
                        color: $text-color
            &:hover
                tr
                    td
                        color: $text-color
    &.selection-table
        width: 100%
        th, td
            padding-bottom: 0.8rem
            padding-top: 0.8rem
        thead
            tr
                th
                    border-bottom: 2px solid $gray-placeholder
                    color: $text-color
                    +rem(font-size, 16px)
                    font-weight: $weight-regular
        thead, tbody tr
            display: table
            table-layout: fixed
            width: 100%
        tbody
            display: block
            height: 250px
            overflow-y: auto
            tr
                td
                    border-bottom: 2px solid $gray-placeholder
                    color: $text-dark
                    +rem(font-size, 16px)
                    font-weight: $weight-regular
        tfoot
            tr
                td
                    +rem(font-size, 14px)
                    font-weight: $weight-regular
    &.cost-table
        width: 100%
        th, td
            color: $text-color
            +rem(font-size, 16px)
            padding: .8rem .6rem
        thead
            tr
                th
                    background-color: $chartreuse //$gray-bg
                    font-weight: $weight-medium
                    text-align: center
        tbody
            tr
                td
                    color: $text-dark
        tfoot
            border-bottom: 1px dotted $border-color
            tr
                td
                    padding: .5rem .6rem
                &:first-child
                    td
                        padding-top: 1rem
                &:last-child
                    td
                        padding-bottom: 1rem
        &.calculated
            tbody
                border-bottom: 1px dotted $border-color
                tr
                    td
                        padding: 1.87rem .6rem
    &.reserve-default
        margin-bottom: 1rem
        width: 100%
        thead
            tr
                th
                    color: $text-color
                    +rem(font-size, 16px)
                    font-weight: $weight-regular
                    padding: .9rem .5rem
                &:first-child
                    th
                        background-color: $chartreuse //$gray-bg
                        color: $black //$blue-200
                        +rem(font-size, 18px)
                        font-weight: $weight-medium
                        padding: .9rem .5rem
                        a
                            color: $text-dark
                            +rem(font-size, 16px)
                            font-weight: $weight-regular
                            text-decoration: underline !important
        tbody
            tr
                td
                    padding: .3rem .5rem
                &:first-child
                    td
                        padding-top: 0.9rem
                &:last-child
                    td
                        padding-bottom: 0.9rem
        tfoot
            tr
                td
                    background-color: $chartreuse  //$blue-200
                    padding: 0.9rem .5rem
        &.dotted
            thead
                tr
                    border-bottom: 1px dotted $border-color
                    &:first-child
                        border-bottom: none
            tbody
                border-bottom: 1px dotted $border-color
                &:last-child
                    border-bottom: none
                tr
                    td
                        vertical-align: top
        &.underline
            tbody
                border-bottom: 1px solid $border-color
                &:last-child
                    border-bottom: none
                    td
                        vertical-align: top

.log-table
    color: $black //#616161
    +rem(font-size, 16px)
    width: 100%
    thead
        tr
            background-color: $chartreuse //$gray-bg
            th
                font-weight: $weight-medium
                padding: 0.8rem 1.2rem
    tbody
        tr
            background-color: transparent
            td
                min-width: 110px
                padding: 0.8rem 1.2rem
            &:nth-child(even)
                background-color: #fafafa
            &:hover
                background-color: #f0f0f0

.table-responsive
    overflow: hidden
    overflow-x: auto