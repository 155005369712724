@keyframes placeHolderShimmer
    0%
        background-color: #ececec
    30%
        background-color: #f7f7f7
    50%
        background-color: #ececec
    80%
        background-color: #f7f7f7
    100%
        background-color: #ececec

@keyframes lds-spinner
    0%
        opacity: 1
    100%
        opacity: 0

.loading-animation
    animation: placeHolderShimmer 3s infinite